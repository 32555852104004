import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

import checks from '../../buildAssets/compliance/tick.svg'
import line from '../../images/li-line.svg'
import question from '../../images/risk/question.svg'

interface PricingGridProps {

}

const Border: FunctionComponent<PricingGridProps> = ({

}) => {
  return (
    <Container>
      <MainGrid>
        
          <PriceRow>
            <InfoColumn>
        
            </InfoColumn>

            <PricingColumn>
             
            </PricingColumn>
            <PricingColumn>
            
            
            </PricingColumn>
            <PricingColumn>
            
            </PricingColumn>
          </PriceRow>
      
      </MainGrid>
    </Container>
  )
}

export default Border

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`
const PriceRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1.2fr 1.2fr;
  column-gap: 5px;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: left;
  min-width: 200px;
  margin: 10px 0;
`

const PricingColumn = styled.div`
  display: flex;
  
  border-top: 2px solid ${theme.grey.keyline};
  
  align-items: center;
  justify-content: center;
  min-width: 220px;
`
