import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  LazyBlocksFactory,
  LazyBlocksFactoryNoParse,
} from '../components/LazyBlocksFactory/LazyBlocksFactory'
import Breadcrumb, {
  BreadCrumbItem,
} from '../components/shared/Breadcrumb/Breadcrumb'
import MainGrid from '../components/styling/MainGrid'
import styled from 'styled-components'
import { parse } from '@wordpress/block-serialization-default-parser'
import theme from '../components/styling/theme'
import Border from '../components/PricingGrid/Border'
interface Props {
  data: any
  pageContext: any
}

const Pricing = ({ data, pageContext }: Props) => {
  const { wpPage } = data
  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  const BreadCrumData: BreadCrumbItem[] = [
    { url: '/', title: 'Home' },
    { url: '', title: 'Pricing' },
  ]

  const lazyBlocks: any = parse(wpPage.lazy_data)

  const gridBlocks = lazyBlocks.map((v) => {
    if (
      v.blockName === 'lazyblock/pricing-cards' ||
      v.blockName === 'lazyblock/pricing-grid-section-header' ||
      v.blockName === 'lazyblock/pricing-grid-item'
    ) {
      return v
    }
  })
  const gridBlocksFiltered = gridBlocks.filter(
    (element) => element !== undefined
  )

  const nonGridBlocks = lazyBlocks.map((v) => {
    if (
      v.blockName !== 'lazyblock/pricing-cards' &&
      v.blockName !== 'lazyblock/pricing-grid-section-header' &&
      v.blockName !== 'lazyblock/pricing-grid-item'
    ) {
      return v
    }
  })
  const nonGridBlocksFiltered = nonGridBlocks.filter(
    (element) => element !== undefined
  )

  const renderedGridBlocks = LazyBlocksFactoryNoParse(
    gridBlocksFiltered,
    wpPage.title
  )
  const renderedNonGridBlocks = LazyBlocksFactoryNoParse(
    nonGridBlocksFiltered,
    wpPage.title
  )

  return (
    <Layout>
      <MainGrid>
        <Breadcrumb data={BreadCrumData} />
      </MainGrid>

      <PricingContainer>
        {/* {renderedBlocks.map((v) => v)} */}
        {renderedGridBlocks.map((v) => v)}
        <Border />
      </PricingContainer>
   
      {renderedNonGridBlocks.map((v) => v)}
    </Layout>
  )
}
export default Pricing

export const pageQuery = graphql`
  query pricing {
    wpPage(title: { eq: "Pricing" }) {
      id
      lazy_data
      title
    }
  }
`

const PricingContainer = styled.div`
  width: 100%;
  overflow-x: scroll;

  @media (min-width: 768px) {
    overflow-x: hidden;
  }
`


